import {
	CONTENT_ANALYTICS_VIEWERS_SEGMENT_KEY,
	createInteractionMetric,
	createPageSegmentLoadMetric,
	createPageLoadMetric,
	CONTENT_ANALYTICS_DIALOG_INTERACTION,
	CONTENT_ANALYTICS_DIALOG_MENTIONS_TAB_INTERACTION,
	PAGE_ANALYTICS_LOAD_KEY,
	SPACE_ANALYTICS_PAGE_LOAD_KEY,
	SITE_ANALYTICS_LOAD_KEY,
} from '@confluence/browser-metrics';
export const CONTENT_ANALYTICS_VIEWERS_METRIC = createPageSegmentLoadMetric({
	key: CONTENT_ANALYTICS_VIEWERS_SEGMENT_KEY,
});

export const CONTENT_ANALYTICS_DIALOG_METRIC = createInteractionMetric({
	key: CONTENT_ANALYTICS_DIALOG_INTERACTION,
});

export const CONTENT_ANALYTICS_DIALOG_MENTIONS_TAB_METRIC = createInteractionMetric({
	key: CONTENT_ANALYTICS_DIALOG_MENTIONS_TAB_INTERACTION,
});

export const PAGE_ANALYTICS_PAGE_LOAD_METRIC = createPageLoadMetric({
	key: PAGE_ANALYTICS_LOAD_KEY,
});

export const SPACE_ANALYTICS_PAGE_LOAD = createPageLoadMetric({
	key: SPACE_ANALYTICS_PAGE_LOAD_KEY,
});

export const SITE_ANALYTICS_PAGE_LOAD = createPageLoadMetric({
	key: SITE_ANALYTICS_LOAD_KEY,
});
