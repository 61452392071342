import { useCallback } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

export const useEventUtils = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireDateChangeEvent = useCallback(
		({
			tabName,
			level,
			dateType,
			source,
		}: {
			tabName: string;
			level: string;
			dateType: string;
			source: string;
		}) =>
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source,
					action: 'clicked',
					actionSubject: 'textField',
					actionSubjectId: 'dateBarAnalytics',
					attributes: {
						dateType,
						tab: tabName,
						level,
						native: true,
					},
				},
			}).fire(),
		[createAnalyticsEvent],
	);

	const fireTabChangeEvent = useCallback(
		({ tabName, level, source }: { tabName: string; level: string; source: string }) =>
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source,
					actionSubject: 'analyticsNavigationItem',
					action: 'clicked',
					actionSubjectId: tabName,
					attributes: {
						native: true,
						level,
					},
				},
			}).fire(),
		[createAnalyticsEvent],
	);

	const fireTableRowClickedEvent = useCallback(
		({
			containerId,
			objectId,
			pageType,
			spaceType,
			level,
			source,
		}: {
			containerId: string;
			objectId?: string;
			pageType?: string;
			spaceType?: string;
			level: string;
			source: string;
		}) =>
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source,
					action: 'clicked',
					actionSubject: 'analyticsLink',
					actionSubjectId: 'spaceAnalytics',
					attributes: {
						native: true,
						level,
						containerId,
						...(objectId ? { objectId } : {}),
						...(pageType ? { pageType } : {}),
						...(spaceType ? { spaceType } : {}),
					},
				},
			}).fire(),
		[createAnalyticsEvent],
	);

	const fireFilterContentChangeEvent = useCallback(
		({
			changedContentType,
			contentTypesList,
			spaceTypesList,
			tabName,
			level,
			source,
		}: {
			changedContentType?: string;
			contentTypesList: string;
			spaceTypesList?: string;
			tabName: string;
			level: string;
			source: string;
		}) =>
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source,
					action: 'clicked',
					actionSubject: 'contentItem',
					actionSubjectId: changedContentType || '',
					attributes: {
						tab: tabName,
						level,
						contentSelected: contentTypesList,
						...(spaceTypesList ? { spaceSelected: spaceTypesList } : {}),
						native: true,
					},
				},
			}).fire(),
		[createAnalyticsEvent],
	);

	const fireDisplayOptionChangeEvent = useCallback(
		({
			newDisplayOption,
			tabName,
			level,
			source,
		}: {
			newDisplayOption: string;
			tabName: string;
			level: string;
			source: string;
		}) =>
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source,
					action: 'clicked',
					actionSubject: 'displayItem',
					actionSubjectId: newDisplayOption,
					attributes: {
						tab: tabName,
						level,
						native: true,
					},
				},
			}).fire(),
		[createAnalyticsEvent],
	);

	const fireMetricChangeEvent = useCallback(
		({
			metricChanged,
			newMetricsList,
			tabName,
			level,
			source,
		}: {
			metricChanged: string;
			newMetricsList: string;
			tabName: string;
			level: string;
			source: string;
		}) =>
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source,
					action: 'clicked',
					actionSubject: 'metricType',
					actionSubjectId: metricChanged,
					attributes: {
						tab: tabName,
						level,
						metricsSelected: newMetricsList,
						native: true,
					},
				},
			}).fire(),
		[createAnalyticsEvent],
	);

	const firePeriodChangeEvent = useCallback(
		({
			newPeriod,
			tabName,
			level,
			source,
		}: {
			newPeriod: string;
			tabName: string;
			level: string;
			source: string;
		}) =>
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source,
					action: 'clicked',
					actionSubject: 'groupByItem',
					actionSubjectId: newPeriod,
					attributes: {
						tab: tabName,
						level,
						native: true,
					},
				},
			}).fire(),
		[createAnalyticsEvent],
	);

	const fireListItemEvent = useCallback(
		({
			listName,
			ranking,
			level,
			source,
			additionalAttributes,
		}: {
			listName: string;
			ranking: number;
			level: string;
			source: string;
			additionalAttributes?: Object;
		}) =>
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source,
					action: 'clicked',
					actionSubject: 'analyticsLink',
					actionSubjectId: listName,
					attributes: {
						level,
						...(ranking ? { ranking } : {}),
						native: true,
						...additionalAttributes,
					},
				},
			}).fire(),
		[createAnalyticsEvent],
	);

	const fireViewAllEvent = useCallback(
		({ listName, level, source }: { listName: string; level: string; source: string }) =>
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source,
					action: 'clicked',
					actionSubject: 'analyticsViewAll',
					actionSubjectId: listName,
					attributes: {
						level,
						native: true,
					},
				},
			}).fire(),
		[createAnalyticsEvent],
	);

	const fireSearchTermClickedEvent = useCallback(
		({ ranking, level, source }: { ranking: number; level: string; source: string }) =>
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source,
					action: 'clicked',
					actionSubject: 'analyticsSearchTerm',
					attributes: {
						level,
						ranking,
						native: true,
					},
				},
			}).fire(),
		[createAnalyticsEvent],
	);

	const fireTableSearchFilterChangedEvent = useCallback(
		({
			tableName,
			isCleared,
			level,
			tabName,
			source,
		}: {
			tableName: string;
			isCleared: boolean;
			level: string;
			tabName: string;
			source: string;
		}) =>
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source,
					action: 'searched',
					actionSubject: tableName,
					attributes: {
						cleared: isCleared,
						level,
						tab: tabName,
						native: true,
					},
				},
			}).fire(),
		[createAnalyticsEvent],
	);

	const fireSendRemindersCtaClickedEvent = useCallback(
		({ level, tabName, source }: { level: string; tabName?: string; source: string }) =>
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source,
					action: 'clicked',
					actionSubject: 'sendRemindersCTA',
					attributes: {
						level,
						tab: tabName,
						native: true,
					},
				},
			}).fire(),
		[createAnalyticsEvent],
	);

	const fireTableCellLinkClickedEvent = useCallback(
		({
			actionName,
			objectId,
			level,
			type,
			source,
			additionalAttributes,
		}: {
			actionName: string;
			objectId: string;
			level: string;
			type: string;
			source: string;
			additionalAttributes?: Object;
		}) =>
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source,
					action: 'clicked',
					actionSubject: actionName,
					attributes: {
						native: true,
						objectId,
						level,
						type,
						...additionalAttributes,
					},
				},
			}).fire(),
		[createAnalyticsEvent],
	);

	return {
		fireDateChangeEvent,
		fireTabChangeEvent,
		fireTableRowClickedEvent,
		fireFilterContentChangeEvent,
		fireDisplayOptionChangeEvent,
		fireMetricChangeEvent,
		firePeriodChangeEvent,
		fireListItemEvent,
		fireViewAllEvent,
		fireSearchTermClickedEvent,
		fireTableSearchFilterChangedEvent,
		fireSendRemindersCtaClickedEvent,
		fireTableCellLinkClickedEvent,
	};
};
